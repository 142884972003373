import { createStore, createLogger } from "vuex";
import quotes from "./modules/quotes.module";
import enquiries from "./modules/enquiries.module";
import auth from "./modules/auth.module";
import session from "./modules/session.module";
import bookings from "./modules/bookings.module";
import { Preferences } from "@capacitor/preferences";
import VuexPersistence from "vuex-persist";
import countries from "./modules/countries";
const debug = process.env.NODE_ENV !== "production";
const vuexIonicStorage = new VuexPersistence({
	restoreState: async (key) => {
		const result = await Preferences.get({ key: key });
		return result.value != null ? JSON.parse(result.value) : null;
	},
	saveState: async (key, state) => {
		await Preferences.set({ key: key, value: JSON.stringify(state) });
	},
	asyncStorage: true,
});

export default createStore({
	modules: {
		quotes,
		bookings,
		enquiries,
		auth,
		session,
		countries,
	},
	actions: {
		async reset({ commit }) {
			await commit("auth/reset");
			await commit("enquiries/reset");
			await commit("quotes/reset");
			commit("session/reset");
			await commit("bookings/reset");
			await Preferences.remove({ key: "vuex" });
		},
	},
	strict: debug,
	plugins: debug ? [createLogger(), vuexIonicStorage.plugin] : [vuexIonicStorage.plugin],
});
