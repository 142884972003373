import QuoteService from "../../services/QuoteService";
import { Quote } from "../../interfaces";
import { Commit } from "vuex";

// initial state
const state = {
	all: [],
	currentQuote: null,
};

// getters
const getters = {
	getQuoteById: (state) => (id: string) => {
		return state.all.find(quote => quote.id === id);
	}
};

// actions
const actions = {
	async getAllQuotes({ commit }: { commit: Commit }) {
		const quotes = await QuoteService.getQuotes();
		commit("setQuotes", quotes);
	},
	async setCurrentQuote({ commit, getters }: { commit: Commit, getters: any }, id: string) {
		const quote = getters.getQuoteById(id);
		if (quote) {
			commit("setCurrentQuote", quote);
		} else {
			// If not found in store, fetch from API
			const fetchedQuote = await QuoteService.getQuoteById(id);
			if (fetchedQuote) {
				commit("setCurrentQuote", fetchedQuote);
			}
		}
	},
	async reset({ commit }: { commit: Commit }) {
		commit("reset");
	},
};

// mutations
const mutations = {
	setQuotes(state: any, quotes: Quote[]) {
		state.all = quotes;
	},
	setCurrentQuote(state: any, quote: Quote) {
		state.currentQuote = quote;
	},
	reset(state: any) {
		state.all = [];
		state.currentQuote = null;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
