class ExceptionService {
	// isEnabled = async () => {
	// 	const result = await FirebaseCrashlytics.isEnabled().catch(() => {
	// 		return { enabled: false };
	// 	});
	// 	return result.enabled;
	// };
	async LogException(errorMessage: any): Promise<void> {
		try {
			// const enabled = await this.isEnabled;
			// if (enabled) {
			// 	await FirebaseCrashlytics.crash({ message: errorMessage }).catch((error) => {
			// 		// eslint-disable-next-line
			// 		console.log("FirebaseCrashlytics Error:", error);
			// 		// eslint-disable-next-line
			// 		console.log("Original Error:", errorMessage);
			// 	});
			// } else {
			// 	// eslint-disable-next-line
			// 	console.log(errorMessage);
			// }
			console.log(errorMessage);
		} catch (e) {
			// eslint-disable-next-line
			console.log(e);
		}
	}
}

export default new ExceptionService();
