import { User } from "../../interfaces";
import { Commit } from "vuex";

// initial state
const state = {
	user: null,
};

// getters
const getters = {
	user: (state: { user: User }) => {
		return state.user;
	},
};

// actions
const actions = {
	async setUser({ commit }: { commit: Commit }, user: User) {
		commit("setUser", user);
	},
	async reset({ commit }: { commit: Commit }) {
		commit("reset");
	},
};

// mutations
const mutations = {
	setUser(state: any, user: User) {
		state.user = user;
	},
	reset(state: any) {
		state.user = null;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
