import { Booking } from "@/classes/bookings/Booking";
import BookingService from "@/services/BookingService";
import { Commit } from "vuex";

// initial state
const state = {
	all: [],
	currentBooking: Booking,
	newBookingCount: 0,
};

// getters
const getters = {};

// actions
const actions = {
	async getAllBookings({ commit }: { commit: Commit }) {
		const bookings = await BookingService.getBookings();
		commit("setBookings", bookings);
	},
	async setCurrentBooking({ commit }: { commit: Commit }, booking: Booking) {
		commit("setCurrentBooking", booking);
	},
	async reset({ commit }: { commit: Commit }) {
		commit("reset");
	},
	async increaseBookingCount({ commit }: { commit: Commit }) {
		commit("increaseBookingCount");
	},
	async clearBookingCount({ commit }: { commit: Commit }) {
		commit("clearBookingCount");
	},
};

// mutations
const mutations = {
	setBookings(state: any, quotes: Booking[]) {
		state.all = quotes;
	},
	setCurrentBooking(state: any, booking: Booking) {
		state.currentBooking = booking;
	},
	reset(state: any) {
		state.all = [];
	},
	clearBookingCount(state: any) {
		state.newBookingCount = 0;
	},
	increaseBookingCount(state: any) {
		state.newBookingCount++;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
