export default {
    namespaced: true,
    state: {
        all: []
        // ... other state properties ...
    },
    mutations: {
        setAll(state, countries) {
            state.all = countries;
        },
        // ... existing mutations ...
    },
    actions: {
        setAllCountries({ commit }, countries) {
            commit("setAll", countries);
        },
        // ... existing actions ...
    },
    getters: {
        all: (state) => state.all,
        // ... existing getters ...
    },
}; 