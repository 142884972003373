import { createApp } from "vue";
import AppMain from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "@/assets/css/fonts.css";
import "@/assets/css/forms.css";
import "./assets/css/global.css";

import axios from "axios";
import store from "./store";
import AuthService from "./services/AuthService";
import { App } from "@capacitor/app";
import HubService from "./services/HubService";
import EventService from "./services/EventService";
import PushNotificationService from "./services/PushNotificationService";
import ExceptionService from "./services/ExceptionService";
import { AuthHeader } from "./services/AuthHeader";

const app = createApp(AppMain).use(IonicVue).use(router).use(store);

router.isReady().then(() => {
	app.mount("#app");
});
axios.interceptors.response.use(
	function (response) {
		return response;
	},
	async function (error) {
		if (error.response.status === 401) {
			console.log("401 hit");
			const successfulRefresh = await AuthService.tokenRefresh();
			console.log("token_refresh: " + successfulRefresh.toString());
			EventService.LogEvent("token_refresh", { wasSuccess: successfulRefresh.toString() });
			if (successfulRefresh) {
				const header = await AuthHeader();
				error.config.headers = header;

				return axios.request(error.config);
			} else {
				await store.dispatch("reset");
				await AuthService.logout();
				router.push("/login");
			}
		}
		return Promise.reject(error);
	}
);

App.addListener("appStateChange", async ({ isActive }) => {
	EventService.LogEvent("app_state_change", { details: `App state changed. Is active: ${isActive}` });
	if (isActive) {
		try {
			const versionFromSession = store.getters["session/version"];
			const info = await App.getInfo();
			const versionCurrently = `${info.build}-${info.version}`;
			if (versionFromSession != versionCurrently) {
				// Force logout as the app has been updated.
				await AuthService.logout();
				router.push("/login");
			}
		} catch (e) {
			ExceptionService.LogException(e);
		}

		EventService.LogEvent("app_state_change", { details: "Calling Active methods" });

		// Calling this will automatically redirect to the login page if a 401 is returned.
		await AuthService.authCheck();

		await HubService.reconnect();
		await PushNotificationService.setupListeners();
	}
});
