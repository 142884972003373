import axios from "axios";
import { AuthHeader } from "./AuthHeader";
import { Profile } from "@/classes/user/Profile";
import store from "@/store";
import { computed } from "vue";

class ProfileService {
	private apiUrl = computed(() => store.getters["session/apiUrl"]);

	async getProfile(): Promise<Profile | null> {
		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};
		return await axios
			.get(`${this.apiUrl.value}/api/members/my-profile`, requestOptions)
			.then(async (response) => {
				return response.data as Profile;
			})
			.catch(() => {
				return null;
			});
	}

	async updateProfile(profile: Profile) {
		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};

		return await axios.post(`${this.apiUrl.value}/api/members/my-profile`, profile, requestOptions).catch(() => {
			return null;
		});
	}
}

export default new ProfileService();
