<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom" color="medium" class="bottom-slot">
        <ion-tab-button
          v-for="(tab, index) in tabs"
          :key="index"
          :tab="tab.title"
          :href="tab.url"
          class="header-color"
          layout="icon-top"
        >
          <div class="profile-icon-container" v-if="tab.title === 'PROFILE'">
            <img v-if="currentProfile?.profilePicture"
              :src="currentProfile.profilePicture"
              alt="Profile Icon"
              class="profile-icon"
            />
          </div>
          <custom-icon
            v-else-if="tab.customIcon"
            :name="tab.customIcon"
            :class="'tab-item-selected icon'"
            class="item-color"
          ></custom-icon>
          <ion-label v-html="tab.title" class="item-color"></ion-label>
          <ion-badge v-if="tab.badgeCount > 0" class="item-color">{{ tab.badgeCount }}</ion-badge>
        </ion-tab-button>
      </ion-tab-bar>

      <div class="footer">
        <div class="btn">
          <ion-icon
            :md="callOutline"
            :ios="callOutline"
            color="#000000"
            size="30"
            class="btIcon"
          ></ion-icon>
          Messages
        </div>
        <div class="btn">
          <ion-icon
            :md="callOutline"
            :ios="callOutline"
            color="#000000"
            size="30"
            class="btIcon"
          ></ion-icon>
          Call Agent
        </div>
      </div>
      <ion-fab
        vertical="bottom"
        horizontal="center"
        class="pad"
      >
        <ion-fab-button :key="2" :href="tabs[2].url">
          <!-- <ion-icon :ios="tabs[2].iosIcon" :md="tabs[2].mdIcon"></ion-icon> -->
          <!-- <img
            src="@/assets/icons/icon-my-trips.svg"
            alt="My Trips Icon"
            class="btIcon fab-icon"
          /> -->
          <div class="fab-icon-mask"></div>
          <custom-icon :name="tabs[2].customIcon" class="btIcon"></custom-icon>
        </ion-fab-button>
      </ion-fab>

      
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts">
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonBadge,
  IonRouterOutlet,
  IonFabButton,
  IonFab,
  loadingController,
  alertController,
  RefresherCustomEvent,
  onIonViewDidEnter
} from "@ionic/vue";
//import { url } from "@vuelidate/validators";
import {
  // arrowBackOutline,
  // arrowBackSharp,
  // homeOutline,
  // bagOutline,
  // bagSharp,
  chatbubblesOutline,
  //chatbubblesSharp,
  callOutline,
 // repeatOutline,
  //repeatSharp,
  mapOutline,
  mapSharp,
  //personCircle,
  //globeOutline,
  //globeSharp,
  //imageOutline,
  //imageSharp,
  //listOutline,
  //listSharp,
  //ellipsisHorizontalOutline,
  //ellipsisHorizontalSharp,
  //documentOutline,
  //documentSharp,
} from "ionicons/icons";
import { computed, ref, Ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import CustomIcon from "@/components/CustomIcon.vue";
import ProfileService from "@/services/ProfileService";
import { Profile } from "@/classes/user/Profile";

export default {
  name: "NavigationTabs",
  components: {
    IonBadge,
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    IonFabButton,
    IonFab,
    CustomIcon,
  },
  setup() {
    const router = useRouter();
    const tabs = ref();
    const store = useStore();
    const currentRouteName = computed(() => router.currentRoute.value.name);
    const newMessageCount = computed(
      () => store.state.enquiries.newMessageCount
    );
    const newBookingCount = computed(
      () => store.state.bookings.newBookingCount
    );
    const newQuoteCount = computed(() => store.state.quotes.newQuoteCount);

    const currentProfile: Ref<Profile> = ref({
				id: "",
				externalId: "",
				applicationUserId: "",
				title: "",
				firstName: "",
				middleName: "",
				lastName: "",
				email: "",
				phone: "",
				phoneSecondary: "",
				dob: "",
				genderId: 0,
				aboutMe: "",
				profilePicture: "",
				createDate: "",
				lasyModifed: "",
				updateContactDetails: false,
				udpateAddresses: false,
				loyaltyPointsBalance: "",
				moreInfo: "",
			});


    // const menuTypeOne = ["home"];
    // const menuTypeTwo = [
    //   "messages",
    //   "quotes",
    //   "bookings",
    //   "specialist",
    //   "things-to-do",
    //   "profile",
    //   "switch-enquiry",
    //   "destination-guide",
    //   "things-to-do",
    //   "debug",
    // ];
    // const menuTypeThree = [
    //   "quote-view",
    //   "quote-destinations",
    //   "quote-itinerary",
    //   "quote-map",
    //   "quote-feedback",
    // ];
    // const menuTypeFour = [
    //   "booking-overview",
    //   "booking-trip-planner",
    //   "booking-view",
    //   "booking-things-to-do",
    //   "booking-passengers",
    //   "booking-destinations",
    //   "booking-documents",
    //   "booking-document-detail",
    //   "booking-itinerary",
    //   "booking-map",
    // ];

    const setupTabs = () => {
      tabs.value = [];
     // var routeName = currentRouteName.value.toString() ?? "";

      tabs.value.push({
        title: "HOME",
        url: router.resolve({ name: "mainmenu" }).href,
        // url: router.resolve({ name: "switch-enquiry" }).href,
        customIcon: "icon-home",
        badgeCount: 0,
      });
      tabs.value.push({
        title: "MESSAGES",
        url: router.resolve({ name: "list-messages" }).href,
        // iosIcon: chatbubblesOutline,
        // mdIcon: chatbubblesSharp,
        customIcon: "icon-messages",
        badgeCount: newMessageCount.value,
      });
      tabs.value.push({
        title: "MY TRIPS",
        url: router.resolve({ name: "my-trips" }).href,
        // url: router.resolve({ name: "quotes" }).href,
        iosIcon: mapOutline,
        mdIcon: mapSharp,
        // customIcon: "icon-my-trips",
        customIcon: "transparent-icon",

        badgeCount: newQuoteCount.value,
      });
      tabs.value.push({
        title: "OFFERS",
        url: router.resolve({ name: "bookings" }).href,
        customIcon: "icon-promotions",
        badgeCount: newBookingCount.value,
      });

      tabs.value.push({
        title: "PROFILE",
        url: "/tabs/profile",
        // iosIcon: personCircle,
        // mdIcon: personCircle,
        customIcon: "profile-icon",
        badgeCount: 0,
      });
    };


    const loadProfile = async () => {
				const loading = await loadingController.create({
					cssClass: "my-custom-class",
					message: "Loading profile",
				});
				loading.present();
				await ProfileService.getProfile().then(
					async (profile: Profile) => {
						currentProfile.value = profile;
						await loading.dismiss();
            // console.log(this.currentProfile);
            // console.log(this.currentProfile.profilePicture);
					},
					async (error) => {
						const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
						const alert = await alertController.create({
							cssClass: "my-custom-class",
							header: "Error",
							subHeader: "Unable to load your profile",
							message: message,
							buttons: ["OK"],
						});

						await loading.dismiss();
						await alert.present();
					}
				);
			};

      const doRefresh = async (event: RefresherCustomEvent) => {
				await loadProfile();
				event.target.complete();
			};

			onIonViewDidEnter(async () => {
        if (router.currentRoute.value.name != 'login') {
          await await loadProfile();
        }
			});

    watch(currentRouteName, async () => {
      setupTabs();
    });
    watch(newMessageCount, async () => {
      setupTabs();
    });
    watch(newBookingCount, async () => {
      setupTabs();
    });
    watch(newQuoteCount, async () => {
      setupTabs();
    });

    setupTabs();

    return {
      tabs,
      chatbubblesOutline,
      callOutline,
      loadProfile,
      doRefresh,
      currentProfile
    };
  },
};
</script>

<style scoped>
.bottom-slot {
  height: 80px;
}

.header-color {
  background-color: white;
}

.btIcon {
  padding-right: 10px;
}


.item-color {
  color: black;
}

.icon {
  width: 34px;
  height: 34px;
  display: inline-block;
  margin-bottom: 8px;
}

.fab-icon-mask {
  margin-left: 10px;
  width: 34px;
  height: 34px;
  background-color: white;
  mask: url("@/assets/icons/icon-my-trips.svg") no-repeat center;
  -webkit-mask: url("@/assets/icons/icon-my-trips.svg") no-repeat center;
}

.profile-icon-container {
  background: linear-gradient(45deg, #f9b733, #d95073, #6044a4);
  border-radius: 50%;
  height: 42px;
  padding: 2px;
  position: relative;
  width: 42px;
  
}

.profile-icon {
  border: 2px solid #ffffff;
  border-radius: 50%;
  height: 38px;
  width: 38px;

}

/***********/

ion-fab {
  margin-bottom: var(--ion-safe-area-bottom, 0);
}

ion-fab-button {
  position: relative;
  bottom: 20px;
}
</style>