import axios from "axios";
import { AuthHeader } from "./AuthHeader";
import { computed } from "vue";
import store from "@/store";
import { Document } from "@/classes/documents/Document";
class DocumentService {
	private apiUrl = computed(() => store.getters["session/apiUrl"]);
	private enquiryId = computed(() => store.getters["enquiries/currentEnquiryId"]);
	async getDocuments(): Promise<Document[] | null> {
		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};
		return await axios
			.get(`${this.apiUrl.value}/api/enquiries/${this.enquiryId.value}/all-documents`, requestOptions)
			.then(async (response) => {
				return response.data as Document[];
			})
			.catch(() => {
				return null;
			});
	}
}

export default new DocumentService();
