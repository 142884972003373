import axios from "axios";
import { ActionPerformed, PushNotifications, PushNotificationSchema } from "@capacitor/push-notifications";
import { AuthHeader } from "./AuthHeader";
import { computed } from "vue";
import store from "@/store";
import { FCM } from "@capacitor-community/fcm";
import { Capacitor } from "@capacitor/core";
import EventService from "./EventService";
import ExceptionService from "./ExceptionService";
import EnquiryService from "./EnquiryService";
import Router from "../router";
import { loadingController } from "@ionic/vue";
import { Device } from "@capacitor/device";
import AuthService from "./AuthService";

class PushNotificationService {
	private apiUrl = computed(() => store.getters["session/apiUrl"]);

	async initPush() {
		const platform = Capacitor.getPlatform(); // -> 'web', 'ios' or 'android'
		if (platform !== "web") {
			await this.registerPush();
		}
	}

	async setupListeners() {
		const platform = Capacitor.getPlatform();
		if (platform === "web") {
			return;
		}
		await PushNotifications.removeAllListeners();

		PushNotifications.addListener("registration", async (token) => {
			let fcmToken = token.value;
			let apnToken = "";
			const platform = Capacitor.getPlatform(); // -> 'web', 'ios' or 'android'
			if (platform === "ios") {
				apnToken = token.value;
				const fcmTokenData = await FCM.getToken();
				fcmToken = fcmTokenData.token;
			}
			await this.savePushNotificationToken(fcmToken, apnToken);
		});
		PushNotifications.addListener("registrationError", (error: any) => {
			ExceptionService.LogException(error);
		});

		PushNotifications.addListener("pushNotificationReceived", async (notification: PushNotificationSchema) => {
			try {
				const authenticated = await AuthService.authCheck();
				if (!authenticated) return;
				const data = notification.notification.data;
				if (data.Type == "BookingAdded") {
					await store.dispatch("bookings/getAllBookings");
				}
				if (data.Type == "BookingDocumentAdded") {
					await store.dispatch("enquiries/getAllDocuments");
				}
				await EventService.LogEvent("push_notifications", { details: `Push Notification Received` });
			} catch (error) {
				ExceptionService.LogException(error);
			}
		});

		PushNotifications.addListener("pushNotificationActionPerformed", async (notification: ActionPerformed) => {
			const loading = await loadingController.create({
				cssClass: "my-custom-class",
			});
			try {
				const authenticated = await AuthService.authCheck();
				if (!authenticated) return;

				const data = notification.notification.data;
				if (data.Type == "EnquiryAdded") {
					Router.push({ name: "switch-enquiry" });
				}
				if (data.Type == "QuoteAdded") {
					Router.push({ name: "quote-view", params: { id: data.QuoteId } });
				}
				if (data.Type == "EnquiryMessageAdded") {
					Router.push({ name: "messages" });
				}
				if (data.Type == "BookingAdded") {
					Router.push({ name: "booking-overview", params: { id: data.BookingId } });
				}
				if (data.Type == "BookingDocumentAdded") {
					Router.push({ name: "booking-documents", params: { id: data.BookingId } });
				}
				await loading.present();
				await EnquiryService.loadEnquiryAfterNotification(data.EnquiryId);
				if (data.Type == "BookingAdded") {
					await store.dispatch("bookings/getAllBookings");
				}
				if (data.Type == "BookingDocumentAdded") {
					await store.dispatch("enquiries/getAllDocuments");
				}
				await EventService.LogEvent("push_notifications", { details: `Push Notification Action Performed` });
			} catch (error) {
				ExceptionService.LogException(error);
			} finally {
				await await loading.dismiss();
			}
		});
	}

	private pushNotificationSaveCount = 0;
	private async savePushNotificationToken(fcmToken, apnToken) {
		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};
		this.pushNotificationSaveCount++;

		const deviceInfo = await Device.getInfo();
		const deviceId = await Device.getId();
		const data = {
			fcmToken: fcmToken,
			apnToken: apnToken,
			deviceId: deviceId.identifier,
			deviceName: deviceInfo.name,
			deviceOs: deviceInfo.operatingSystem,
			deviceManufacturer: deviceInfo.manufacturer,
			deviceModel: deviceInfo.model,
			deviceOsVersion: deviceInfo.osVersion,
			devicePlatform: deviceInfo.platform,
		};

		await axios
			.post(`${this.apiUrl.value}/api/members/push-notification-registration`, JSON.stringify(data), requestOptions)
			.then(() => this.pushNotificationSaveCount == 0)
			.catch(async (error) => {
				if (this.pushNotificationSaveCount == 1) {
					alert("There was an issue registering you for notifications.");
					await ExceptionService.LogException(error);
				}

				if (this.pushNotificationSaveCount < 5) {
					await this.savePushNotificationToken(fcmToken, apnToken);
				}
			});
	}

	private async registerPush() {
		await this.setupListeners();
		PushNotifications.requestPermissions().then(async (permissionStatus) => {
			await await EventService.LogEvent("push_notifications", { details: "Request Push Notification Permissions", status: permissionStatus.receive.toString() });
			if (permissionStatus.receive == "granted") {
				// Register with Apple / Google to receive push via APNS/FCM
				await PushNotifications.register();
				await await EventService.LogEvent("push_notifications", { details: "Push Notifications Subscribed" });
			} else {
				// No permission for push granted
				await await EventService.LogEvent("push_notifications", { details: "Push Notifications Rejected" });
			}
		});
	}
}

export default new PushNotificationService();
