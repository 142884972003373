export class Booking {
	Id: string;
	Reference: string;
	DepartureDate: string;
	Passengers: [] = [];
	ReturnDate: string;
	TotalPassengerCount: string;
	TotalCost: string;
	bannerUrl: string;
	friendlyName: string;
}
