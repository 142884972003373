import EnquiryService from "../../services/EnquiryService";
import { Commit } from "vuex";
import { Specialist } from "@/classes/enquiries/Specialist";
import DocumentService from "@/services/DocumentService";
import MessageService from "@/services/MessageService";
import HubService from "@/services/HubService";
import { Message } from "@/classes/messages/Message";
import { EnquiryItem, EnquirySwitcher } from "@/classes/enquiries/EnquirySwitcher";

// initial state
const state = {
	switchData: [],
	selectedEnquiryId: "",
	currentSpecialist: null,
	messages: [],
	newMessageCount: 0,
	documents: [],
	currentEnquiry: null,
};

// getters
const getters = {
	currentSpecialist: (state: { currentSpecialist: Specialist }) => {
		return state.currentSpecialist;
	},
	currentEnquiryId: (state: { selectedEnquiryId: string }) => {
		return state.selectedEnquiryId;
	},
	currentEnquiry: (state: { currentEnquiry: EnquiryItem }) => {
		return state.currentEnquiry;
	},
	messages: (state: { messages: Message[] }) => {
		return state.messages;
	},
	enquirySwitchData: (state: { switchData: EnquirySwitcher | undefined }) => {
		return state.switchData;
	},
};

// actions
const actions = {
	async getEnquirySwitchData({ commit }: { commit: Commit }): Promise<EnquirySwitcher | undefined> {
		const enquirySwitchData = await EnquiryService.getEnquiriesQuotesAndBookings();
		commit("setEnquirySwitchData", enquirySwitchData);
		return enquirySwitchData;
	},
	async setEnquirySwitchData({ commit }: { commit: Commit }, switchData: EnquirySwitcher) {
		commit("setEnquirySwitchData", switchData);
	},
	async setEnquiry({ commit }: { commit: Commit }, enquiry: EnquiryItem) {
		commit("setEnquiry", enquiry);
		commit("setEnquiryId", enquiry.id);

		const isConnected = await HubService.checkConnectionState();
		if (isConnected) {
			await HubService.leaveEnquiryNotificationGroup(enquiry.id);
			await HubService.joinEnquiryNotificationGroup(enquiry.id);
		} else {
			await HubService.initialiseConnection();
			await HubService.joinEnquiryNotificationGroup(enquiry.id);
		}

		// Specilaist
		const specialist = await EnquiryService.getSpecialist();
		commit("setSpecialist", specialist);

		// Documents
		const documents = await DocumentService.getDocuments();
		commit("setDocuments", documents);

		// Messages
		const messages = await MessageService.getMessages();
		commit("setMessages", messages);
	},
	async increaseMessageCount({ commit }: { commit: Commit }) {
		commit("increaseMessageCount");
	},
	async clearMessageCount({ commit }: { commit: Commit }) {
		commit("clearMessageCount");
	},
	async getAllMessages({ commit }: { commit: Commit }) {
		const messages = await MessageService.getMessages();
		commit("setMessages", messages);
	},
	async getAllDocuments({ commit }: { commit: Commit }) {
		const documents = await DocumentService.getDocuments();
		commit("setDocuments", documents);
	},
	async reset({ commit }: { commit: Commit }) {
		commit("reset");
	},
};

// mutations
const mutations = {
	setEnquiry(state: any, enquiry: EnquiryItem) {
		state.currentEnquiry = enquiry;
	},
	setEnquirySwitchData(state: any, enquiries: EnquirySwitcher) {
		state.switchData = enquiries;
	},
	setEnquiryId(state: any, enquiryId: string) {
		state.selectedEnquiryId = enquiryId;
	},
	setSpecialist(state: any, specialist: Specialist) {
		state.currentSpecialist = specialist;
	},
	increaseMessageCount(state: any) {
		state.newMessageCount++;
	},
	clearMessageCount(state: any) {
		state.newMessageCount = 0;
	},
	setMessages(state: any, messages: Message[]) {
		state.messages = messages;
	},
	setDocuments(state: any, documents: Document[]) {
		state.documents = documents;
	},
	reset(state: any) {
		state.all = [];
		state.selectedEnquiryId = "";
		state.currentSpecialist = null;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
