import { Brand } from "@/classes/tenants/Brand";
import router from "@/router";
import { Commit } from "vuex";
// initial state
const state = {
	currentBrand: null,
	availableBrands: [],
	version: "N/A",
};

// getters
const getters = {
	apiUrl: (state: { currentBrand: { url: any } | null }) => {
		if (state.currentBrand == null || state.currentBrand.url == "") {
			router.push("/login");
		}
		return state.currentBrand != null ? state.currentBrand.url : "";
	},
	brand: (state: { currentBrand: Brand }) => {
		return state.currentBrand;
	},
	version: (state: { version: string }) => {
		return state.version;
	},
};

// actions
const actions = {
	setSelectedBrand({ commit }: { commit: Commit }, brand: Brand) {
		commit("setCurrentBrand", brand);
	},
	setAvailableBrands({ commit }: { commit: Commit }, brands: Brand[]) {
		commit("setAvailableBrands", brands);
	},
	setVersion({ commit }: { commit: Commit }, version: string) {
		commit("setVersion", version);
	},
	reset({ commit }: { commit: Commit }) {
		commit("reset");
	},
	fullReset({ commit }: { commit: Commit }) {
		commit("fullReset");
	},
};

// mutations
const mutations = {
	setCurrentBrand(state: any, brand: Brand) {
		state.currentBrand = brand;
	},
	setAvailableBrands(state: any, brands: Brand[]) {
		state.availableBrands = brands;
	},
	setVersion(state: any, version: string) {
		state.version = version;
	},
	reset(state: any) {
		state.version = "N/A";
		// Don't reset the brand so they can quickly log back in
		//state.currentBrand = null;
		state.availableBrands = [];
		state.activeEnquiry = null;
	},
	fullReset(state: any) {
		state.version = "N/A";
		state.currentBrand = null;
		state.availableBrands = [];
		state.activeEnquiry = null;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
