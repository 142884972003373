import axios from "axios";
import { AuthHeader } from "./AuthHeader";
import { computed } from "vue";
import store from "@/store";
import { Message } from "@/classes/messages/Message";
class MessageService {
	private apiUrl = computed(() => store.getters["session/apiUrl"]);
	private enquiryId = computed(() => store.getters["enquiries/currentEnquiryId"]);
	async getMessages(): Promise<Message[] | null> {
		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};
		return await axios
			.get(`${this.apiUrl.value}/api/enquiries/enquiry-messages/${this.enquiryId.value}`, requestOptions)
			.then(async (response) => {
				return (response.data as Message[]).reverse();
			})
			.catch(() => {
				return [];
			});
	}

	async sendMessage(message: string): Promise<void> {
		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};
		const postData = {
			subject: "Customer Message",
			text: message,
			enquiryId: this.enquiryId.value,
			type: "Message",
		};
		await axios.post(`${this.apiUrl.value}/api/enquiries/enquiry-history`, postData, requestOptions).catch(() => {
			alert("Unable to send message");
		});
		await store.dispatch("enquiries/getAllMessages");
	}
}

export default new MessageService();
