import { Preferences } from "@capacitor/preferences";

export async function AuthHeader() {
	const userData = await Preferences.get({ key: "user" });

	if (userData.value == null) return {};
	const user = JSON.parse(userData.value ? userData.value : "");
	if (user && user.token) {
		return { "Authorization": "Bearer " + user.token, "Content-Type": "application/json" };
	} else {
		return { "Content-Type": "application/json" };
	}
}
