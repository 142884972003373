<template>
	<ion-app>
		<ion-router-outlet />
	</ion-app>
</template>

<script lang="ts">
	import { IonApp, IonRouterOutlet } from "@ionic/vue";
	import { defineComponent, computed, watch, ref } from "vue";
	import { useRouter } from "vue-router";
	import { useStore } from "vuex";

	export default defineComponent({
		name: "App",
		components: {
			IonApp,
			IonRouterOutlet,
		},
		setup() {
			const brand = computed(() => store.getters["session/brand"]);
			const store = useStore();
			const router = useRouter();
			let colour = ref("");

			const user = computed(() => store.state.auth.user);

			if (user.value == null) {
				router.push("/login");
			}

			watch(brand, async () => {
				colour.value = brand.value == null ? "" : brand.value.primaryColour;
			});

			return { colour };
		},
	});
</script>
<style>
	/* .button {
		background-color: v-bind(colour) !important;
	} */
</style>
