import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import { Preferences } from "@capacitor/preferences";
import store from "@/store";
import NavigationTabs from "../views/NavigationTabs.vue";
const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		redirect: "/tabs/home",
	},
	{
		path: "",
		redirect: "/tabs/home",
	},
	{
		name: "login",
		path: "/login",
		component: () => import("../views/LoginScreen.vue"),
	},
	{
		path: "/tabs",
		component: NavigationTabs,
		children: [
			{
				path: "",
				redirect: "/tabs/home",
			},
			{
				name: "home",
				path: "home",
				component: () => import("@/views/MainMenuList.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "welcome",
				path: "welcome",
				component: () => import("@/views/Welcome.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "debug",
				path: "debug",
				component: () => import("@/views/AdminDebug.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "mainmenu",
				path: "mainmenu",
				component: () => import("@/views/MainMenuList.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "my-trips",
				path: "my-trips",
				component: () => import("@/views/MyTrips.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "trip-details",
				path: "trip-details/:id",
				component: () => import("@/views/MyTripsDetails.vue"),
				meta: {
					requiresAuth: true,
				},
				props: (route) => ({
					id: route.params.id,
					type: route.query.type, // Extract 'type' from query params
				}), // This allows route params to be passed as props as i want to define type booking or enquiry
			},
			{
				name: "country-guide",
				path: "country-guide/:id",
				component: () => import("@/views/CountryGuide.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "region-guides",
				path: "region-guides/:id",
				component: () => import("@/views/RegionGuides.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "points-of-interest-list",
				path: "points-of-interest-list/:id?",
				component: () => import("@/views/PointsOfInterestList.vue"),
				meta: {
					requiresAuth: true,
				},
				props: (route) => ({
					id: route.params.id || null,
					type: route.query.type || "all", // Defaults to 'all' if not provided
				}),
			},
			{
				name: "points-of-interest-detail",
				path: "points-of-interest-detail/:id",
				component: () => import("@/views/PointsOfInterestDetail.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "documents-tickets",
				path: "documents-tickets",
				component: () => import("@/views/DocumentsTickets.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "switch-enquiry",
				path: "switch-enquiry",
				component: () => import("@/views/EnquirySwitcher.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "list-messages",
				path: "list-messages",
				component: () => import("@/views/MessagesList.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "messages",
				path: "messages",
				component: () => import("@/views/MessagesTrip.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "profile",
				path: "profile",
				component: () => import("@/views/UserProfile.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "specialist",
				path: "specialist",
				component: () => import("@/views/SpecialistDetails.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "destination-guide",
				path: "destination-guide/:id",
				component: () => import("@/views/TripDestinations.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "things-to-do",
				path: "things-to-do",
				component: () => import("@/views/ThingsToDo.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "journal",
				path: "journal",
				component: () => import("@/views/Journal.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "offers-and-promotions",
				path: "offers-and-promotions",
				component: () => import("@/views/OffersAndPromotions.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "quotes",
				path: "quotes/:id?", // Make the id parameter optional
				component: () => import("@/views/AllQuotes.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "my-quote-detail",
				path: "my-quote-detail/:id", // Make the id parameter optional
				component: () => import("@/views/MyQuoteDetail.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "quote-view",
				path: "quote-view/:id",
				component: () => import("@/views/QuoteDetail.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "quote-destinations",
				path: "quote-destinations/:id",
				component: () => import("@/views/TripDestinations.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "quote-itinerary",
				path: "quote-itinerary/:id",
				component: () => import("@/views/QuoteItinerary.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "quote-map",
				path: "quote-map/:id",
				component: () => import("@/views/MapView.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "quote-feedback",
				path: "quote-feedback/:id",
				component: () => import("@/views/QuoteFeedback.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "bookings",
				path: "bookings",
				component: () => import("@/views/AllBookings.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "booking-overview",
				path: "booking-overview/:id",
				component: () => import("@/views/BookingOverview.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "booking-trip-planner",
				path: "booking-trip-planner/:id",
				component: () => import("@/views/TripDestinations.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "booking-view",
				path: "booking-view/:id",
				component: () => import("@/views/BookingDetail.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "booking-things-to-do",
				path: "booking-things-to-do/:id",
				component: () => import("@/views/ThingsToDo.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "booking-passengers",
				path: "booking-passengers/:id",
				component: () => import("@/views/BookingPassengers.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "booking-destinations",
				path: "booking-destinations/:id",
				component: () => import("@/views/TripDestinations.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "booking-documents",
				path: "booking-documents/:id",
				component: () => import("@/views/BookingDocuments.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "booking-document-detail",
				path: "booking-document-detail/:id",
				component: () => import("@/views/DocumentDetail.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "booking-itinerary",
				path: "booking-itinerary/:id",
				component: () => import("@/views/BookingItinerary.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "booking-map",
				path: "booking-map/:id",
				component: () => import("@/views/MapView.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "headers",
				path: "headers",
				component: () => import("@/views/HeaderTestPage.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "currency-converter",
				path: "currency-converter",
				component: () => import("@/views/CurrencyConvertor.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "weather",
				path: "weather",
				component: () => import("@/views/Weather.vue"),
				meta: {
					requiresAuth: true,
				},
			},
		],
	},
];
const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: routes,
});
router.beforeEach(async (to, from, next) => {
	await (store as any).restored;
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		const userData = await Preferences.get({ key: "user" });
		if (userData.value == null) {
			next({
				path: "/login",
			});
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router;
