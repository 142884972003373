import axios from "axios";
import { AuthHeader } from "./AuthHeader";
import { Quote } from "../interfaces/index";
import store from "@/store";
import { computed } from "vue";
import { QuoteDetail } from "@/classes/quotes/QuoteDetail";

class QuoteService {
	private apiUrl = computed(() => store.getters["session/apiUrl"]);
	private enquiryId = computed(() => store.getters["enquiries/currentEnquiryId"]);
	async getQuotes(): Promise<Quote[] | null> {
		if (this.enquiryId.value == null || this.enquiryId.value == "") {
			return [];
		}

		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};
		return await axios
			.get(`${this.apiUrl.value}/api/quotes/my-quotes/${this.enquiryId.value}`, requestOptions)
			.then(async (response) => {
				return (response.data as Quote[]).reverse();
			})
			.catch(() => {
				return [];
			});
	}

	async getQuoteById(quoteId: string): Promise<QuoteDetail | null> {
		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};
		return await axios
			.get(`${this.apiUrl.value}/api/quotes/${quoteId}`, requestOptions)
			.then(async (response) => {
				return response.data as QuoteDetail;
			})
			.catch(() => {
				return null;
			});
	}
}

export default new QuoteService();
